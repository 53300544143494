import axios from '@/apis/axios';
import { getAccessToken } from '@/lib/api';

/**
 * ロゴとメッセージの登録
 */
export const registerLogoAndMessage = async (logo, message, deleteFlg) => {
  const accessToken = await getAccessToken();

  const form = new FormData();
  form.append('message', message);
  form.append('files[0]', logo);
  form.append('deleteFlg', deleteFlg);
  const response = await axios
    .post(`/agencies/agencies/management_logo_and_message/register`, form, {
      headers: {
        'access-token': accessToken,
        'content-type': 'multipart/form-data',
      },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * ロゴ画像の取得
 */
export const getLogo = async () => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/agencies/management_logo_and_message/logo`, {
      responseType: 'arraybuffer',
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return { blob: new Blob([response.data]) };
};

/**
 * メッセージの取得
 */
export const getMessage = async () => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/agencies/management_logo_and_message/message`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};
