<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout column wrap>
      <v-row>
        <v-col>
          <p class="font-weight-black pl-3" style="font-size:32px">
            {{ $t('link.registerLogoAndMessage') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-container>
          <v-row>
            <v-col>
              <ValidationForms ref="forms">
                <!-- ロゴ -->
                <v-row>
                  <div
                    class="input-item"
                    :class="conditionStyle"
                    style="width:100%;"
                  >
                    <label id="uploadBtn" class="input-item__label">
                      <input
                        type="file"
                        accept="image/*"
                        ref="input"
                        @change="onFileUploaded"
                        style="width:100%;"
                      />{{ $t('label.selectFile') }}
                    </label>
                    <p class="px-1" style="display:inline">
                      {{ displayFileName }}
                    </p>
                    <p class="pt-1" style="font-size:10px;">
                      {{ $t('label.uploadLimitFileSize') }}
                      <v-btn
                        class="mx-auto float-right"
                        align="center"
                        width="5vw"
                        max-height="25px"
                        max-width="30px"
                        style="font-size:15px"
                        dark
                        rounded
                        @click="onClickDeleteButton()"
                        >{{ $t('button.delete') }}</v-btn
                      >
                    </p>
                  </div>
                </v-row>
                <!-- メッセージ -->
                <v-row class="pt-4">
                  <v-col :class="conditionStyle">
                    <ValidationForm :rules="`max:${maxMessageLength}`">
                      <v-textarea
                        class="mx-auto"
                        v-model="condition.message"
                        outlined
                        :counter="maxMessageLength"
                        :label="$t('label.message')"
                      ></v-textarea>
                    </ValidationForm>
                  </v-col>
                </v-row>
                <v-row class="pt-4">
                  <v-btn
                    width="33%"
                    class="back mx-auto"
                    dark
                    @click="onClickCancelButton()"
                    >{{ $t('button.cancel') }}</v-btn
                  >
                  <v-btn
                    width="33%"
                    class="next mx-auto"
                    dark
                    @click="onClickUpdateButton()"
                    >{{ $t('button.update') }}</v-btn
                  >
                </v-row>
              </ValidationForms>
            </v-col>
            <!-- プレビュー -->
            <v-col>
              <p class="mx-auto">{{ $t('label.preview') }}</p>
              <v-card style="width: 380px" outlined>
                <v-container v-if="upperLogo" fluid>
                  <v-layout column fill-height wrap>
                    <v-container
                      style="width: 90%; max-width: 450px; max-height=90vh;"
                      class="mx-auto"
                    >
                      <v-row class="mt-12">
                        <v-flex xs12>
                          <v-img
                            class="mx-auto"
                            :src="upperLogo"
                            width="100%"
                            max-width="200px"
                          ></v-img>
                        </v-flex>
                      </v-row>
                      <v-row>
                        <p
                          class="font-weight-black text-center fx-20 mx-auto"
                          style="font-size:20px; margin-top:12px;"
                        >
                          {{ $t('systemName') }}
                        </p>
                      </v-row>
                      <v-row class="mt-12">
                        <v-flex xs12>
                          <v-img
                            class="mx-auto"
                            src="@/assets/img/top.gif"
                            width="70%"
                            max-width="350px"
                          ></v-img>
                        </v-flex>
                      </v-row>
                      <v-row class="mt-2 ml-2 mr-2">
                        <v-flex xs12>
                          <v-img
                            class="mx-auto"
                            src="@/assets/img/copylight.gif"
                            width="30%"
                            max-width="100px"
                          ></v-img>
                        </v-flex>
                      </v-row>
                      <div>
                        <v-row v-if="downerLogo" class="mt-4">
                          <v-img
                            :contain="true"
                            max-height="300px"
                            width="100%"
                            max-width="200px"
                            :src="downerLogo"
                            class="mx-auto"
                          />
                        </v-row>
                      </div>
                      <p class="mt-4">{{ condition.message }}</p>
                      <v-row class="my-12">
                        <v-btn
                          class="font-weight-black mx-auto"
                          align="center"
                          width="70%"
                          max-width="350px"
                          color="next"
                          style="font-size:20px"
                          dark
                          rounded
                          >{{ $t('button.start') }}</v-btn
                        >
                      </v-row>
                    </v-container>
                  </v-layout>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <ErrorDialog ref="errorDialog"></ErrorDialog>
      <CompletedDialog ref="completedDialog"></CompletedDialog>
      <!-- 削除確認 -->
      <SimpleDialog
        :showDialog="showConfirmDialog"
        :title="$t('title.agency.logoAndMessageEdit')"
        :text="null"
        :subText="null"
        :multiLineText="null"
        :confirmText="$t('description.updateLogoAndMessage')"
        :negativeButtonTitle="$t('button.no')"
        :positiveButtonTitle="$t('button.yes')"
        :onClickNegativeButton="closeConfirmDialog"
        :onClickPositiveButton="update"
      />
    </v-layout>
  </v-container>
</template>
<script>
import ValidationForms from '@/components/organisms/common/ValidationForms';
import ValidationForm from '@/components/organisms/common/ValidationForm';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import SimpleDialog from '@/components/organisms/agency/SimpleDialog';
import { uploadLimitFileSize, MaxLength } from '@/lib/const';
import {
  getLogo,
  getMessage,
  registerLogoAndMessage,
} from '@/apis/agency/logoAndMessage';
import mainLogo from '@/assets/img/logo.gif';

export default {
  name: 'RegisterLogoAndMessage',
  components: {
    ValidationForms,
    ValidationForm,
    CompletedDialog,
    ErrorDialog,
    SimpleDialog,
  },
  data() {
    return {
      // 編集可能な項目
      condition: {
        logo: null,
        message: '',
      },
      // 編集フォームのスタイル指定
      conditionStyle: ['my-0', 'py-0', 'mx-4', 'px-4'],
      upperLogo: '',
      downerLogo: '',
      displayFileName: null,
      maxMessageLength: MaxLength.AgencyMessage,
      showConfirmDialog: false,
      fileDeleteFlg: false,
      isCancelButtonClicked: false,
    };
  },
  async mounted() {
    // ロゴ・メッセージ情報取得
    await this.fetchLogoAndMessage();
  },
  methods: {
    async fetchLogoAndMessage() {
      const logoResponse = await getLogo();
      if (logoResponse.blob.size) {
        console.log('logoResponse')
        const url = window.URL || window.webkitURL;
        const img = new Image();
        const src = url.createObjectURL(logoResponse.blob);
        const parent = this;
        if (this.fileDeleteFlg && !this.isCancelButtonClicked) {
          this.$set(this, 'upperLogo', mainLogo);
          this.$set(this, 'downerLogo', null);
          parent.$set(parent, 'fileDeleteFlg', false);
          parent.$set(parent, 'isCancelButtonClicked', false);
        } else {
          img.onload = () => {
            parent.$set(parent, 'fileDeleteFlg', false);
            parent.$set(parent.condition, 'logo', null);
            parent.$set(parent, 'downerLogo', mainLogo);
            parent.$set(parent, 'upperLogo', src);
          };
        } 

        img.src = src;
      } else {
        this.$set(this, 'fileDeleteFlg', true);
        this.$set(this, 'upperLogo', mainLogo);
        this.$set(this, 'downerLogo', null);
      }
      const messageResponse = await getMessage();
      this.condition.message = messageResponse.message;
      this.displayFileName = messageResponse.logoFileName
        ? messageResponse.logoFileName
        : this.$t('label.pleaseSelect');
    },

    async update() {
      // バリデーション
      if (!this.$refs.forms.validate()) {
        this.onErrorUpdate();
        return;
      }
      this.closeConfirmDialog();
      // 編集項目の保持
      const condition = {
        logo: this.condition.logo ? this.condition.logo.fileData : null,
        message: this.condition.message ? this.condition.message : '',
      };
      // ロゴとメッセージの登録APIを呼ぶ
      await registerLogoAndMessage(
        condition.logo,
        condition.message,
        this.fileDeleteFlg
      );
      this.$refs.completedDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('success.updated')
      );
      await this.fetchLogoAndMessage();
    },
    async onClickCancelButton() {
      this.$refs.input.value = '';
      this.$set(this, 'isCancelButtonClicked', true);
      await this.fetchLogoAndMessage();
    },
    async onClickUpdateButton() {
      // バリデーションエラーが発生した場合、以降の処理を中止する
      if (!(await this.$refs.forms.validate())) return;

      this.$set(this, 'showConfirmDialog', true);
    },
    onClickDeleteButton() {
      this.$refs.input.value = '';
      this.$set(this.condition, 'logo', null);
      this.$set(this, 'displayFileName', this.$t('label.pleaseSelect'));
      this.$set(this, 'fileDeleteFlg', true);
      this.$set(this, 'upperLogo', mainLogo);
      this.$set(this, 'downerLogo', null);
    },
    closeConfirmDialog() {
      this.$set(this, 'showConfirmDialog', false);
    },
    onErrorUpdate() {
      this.$refs.errorDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('error.updateFailed')
      );
    },
    onFileUploaded: function(e) {
      this.$set(this, 'fileDeleteFlg', false);
      const files = e.target.files || e.dataTransfer.files;
      if (files[0] && files[0].size > uploadLimitFileSize) {
        this.$refs.errorDialog.open(
          this.$t('label.fileUploadError'),
          this.$t('error.uploadLogoFileSizeOverLimit')
        );
      }
      const reader = new FileReader();
      const parent = this;
      reader.onload = v => {
        const img = new Image();
        img.src = v.target.result;
        img.onload = function() {
          if (files[0].size > uploadLimitFileSize) {
            parent.condition.logo = null;
            e.target.value = '';
          } else {
            const contentType = v.target.result
              .toString()
              .slice(
                v.target.result.indexOf(':') + 1,
                v.target.result.indexOf(';')
              );
            parent.condition.logo = {
              name: files[0].name,
              file: v.target.result,
              fileData: files[0],
              contentType: contentType,
              width: img.width,
              height: img.height,
            };
            parent.$set(parent, 'downerLogo', mainLogo);
            parent.$set(parent, 'upperLogo', v.target.result);
            parent.$set(parent, 'displayFileName', files[0].name);
          }
        };
      };
      if (files[0]) {
        reader.readAsDataURL(files[0]);
      }
    },
  },
};
</script>
<style>
#uploadBtn > input {
  display: none;
}

#uploadBtn {
  padding: 2px 8px;
  background: #efefef;
  color: #000000;
  border: 1px solid;
  cursor: default;
}
</style>
