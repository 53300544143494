var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pl-12 pr-12", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", wrap: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "p",
                  {
                    staticClass: "font-weight-black pl-3",
                    staticStyle: { "font-size": "32px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("link.registerLogoAndMessage")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "ValidationForms",
                            { ref: "forms" },
                            [
                              _c("v-row", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "input-item",
                                    class: _vm.conditionStyle,
                                    staticStyle: { width: "100%" }
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "input-item__label",
                                        attrs: { id: "uploadBtn" }
                                      },
                                      [
                                        _c("input", {
                                          ref: "input",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            type: "file",
                                            accept: "image/*"
                                          },
                                          on: { change: _vm.onFileUploaded }
                                        }),
                                        _vm._v(
                                          _vm._s(_vm.$t("label.selectFile")) +
                                            "\n                  "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "px-1",
                                        staticStyle: { display: "inline" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.displayFileName) +
                                            "\n                  "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "pt-1",
                                        staticStyle: { "font-size": "10px" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "label.uploadLimitFileSize"
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mx-auto float-right",
                                            staticStyle: {
                                              "font-size": "15px"
                                            },
                                            attrs: {
                                              align: "center",
                                              width: "5vw",
                                              "max-height": "25px",
                                              "max-width": "30px",
                                              dark: "",
                                              rounded: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.onClickDeleteButton()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("button.delete"))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "v-row",
                                { staticClass: "pt-4" },
                                [
                                  _c(
                                    "v-col",
                                    { class: _vm.conditionStyle },
                                    [
                                      _c(
                                        "ValidationForm",
                                        {
                                          attrs: {
                                            rules: "max:" + _vm.maxMessageLength
                                          }
                                        },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mx-auto",
                                            attrs: {
                                              outlined: "",
                                              counter: _vm.maxMessageLength,
                                              label: _vm.$t("label.message")
                                            },
                                            model: {
                                              value: _vm.condition.message,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.condition,
                                                  "message",
                                                  $$v
                                                )
                                              },
                                              expression: "condition.message"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "pt-4" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "back mx-auto",
                                      attrs: { width: "33%", dark: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickCancelButton()
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "next mx-auto",
                                      attrs: { width: "33%", dark: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickUpdateButton()
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("button.update")))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("p", { staticClass: "mx-auto" }, [
                            _vm._v(_vm._s(_vm.$t("label.preview")))
                          ]),
                          _c(
                            "v-card",
                            {
                              staticStyle: { width: "380px" },
                              attrs: { outlined: "" }
                            },
                            [
                              _vm.upperLogo
                                ? _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            column: "",
                                            "fill-height": "",
                                            wrap: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              staticClass: "mx-auto",
                                              staticStyle: {
                                                width: "90%",
                                                "max-width": "450px"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-12" },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c("v-img", {
                                                        staticClass: "mx-auto",
                                                        attrs: {
                                                          src: _vm.upperLogo,
                                                          width: "100%",
                                                          "max-width": "200px"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c("v-row", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "font-weight-black text-center fx-20 mx-auto",
                                                    staticStyle: {
                                                      "font-size": "20px",
                                                      "margin-top": "12px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t("systemName")
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-12" },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c("v-img", {
                                                        staticClass: "mx-auto",
                                                        attrs: {
                                                          src: require("@/assets/img/top.gif"),
                                                          width: "70%",
                                                          "max-width": "350px"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "mt-2 ml-2 mr-2"
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c("v-img", {
                                                        staticClass: "mx-auto",
                                                        attrs: {
                                                          src: require("@/assets/img/copylight.gif"),
                                                          width: "30%",
                                                          "max-width": "100px"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                [
                                                  _vm.downerLogo
                                                    ? _c(
                                                        "v-row",
                                                        { staticClass: "mt-4" },
                                                        [
                                                          _c("v-img", {
                                                            staticClass:
                                                              "mx-auto",
                                                            attrs: {
                                                              contain: true,
                                                              "max-height":
                                                                "300px",
                                                              width: "100%",
                                                              "max-width":
                                                                "200px",
                                                              src:
                                                                _vm.downerLogo
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c("p", { staticClass: "mt-4" }, [
                                                _vm._v(
                                                  _vm._s(_vm.condition.message)
                                                )
                                              ]),
                                              _c(
                                                "v-row",
                                                { staticClass: "my-12" },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "font-weight-black mx-auto",
                                                      staticStyle: {
                                                        "font-size": "20px"
                                                      },
                                                      attrs: {
                                                        align: "center",
                                                        width: "70%",
                                                        "max-width": "350px",
                                                        color: "next",
                                                        dark: "",
                                                        rounded: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("button.start")
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("ErrorDialog", { ref: "errorDialog" }),
          _c("CompletedDialog", { ref: "completedDialog" }),
          _c("SimpleDialog", {
            attrs: {
              showDialog: _vm.showConfirmDialog,
              title: _vm.$t("title.agency.logoAndMessageEdit"),
              text: null,
              subText: null,
              multiLineText: null,
              confirmText: _vm.$t("description.updateLogoAndMessage"),
              negativeButtonTitle: _vm.$t("button.no"),
              positiveButtonTitle: _vm.$t("button.yes"),
              onClickNegativeButton: _vm.closeConfirmDialog,
              onClickPositiveButton: _vm.update
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }